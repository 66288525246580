<template lang="pug">
  div.text-center.error-page
    img(:src="Error" alt="error")
    p Page not found
    p But you can go
      |
      router-link(:to="{ name: 'HomePage' }") Back
</template>

<script>
import Error from '@/assets/images/error.svg'

export default {
  name: 'ErrorPage',
  metaInfo () {
    return this.$metaDefault({ robots: 'noindex, follow' })
  },
  data () {
    return {
      Error
    }
  }
}
</script>
